<template>
    <div class="body">
        <!-- 红尘坊申请退款/退货退款-->
        <!--导航栏--->
        <section class="top">
            <van-nav-bar :title="serviceType == 0 ? '我要退款' : '我要退货退款'" safe-area-inset-top left-arrow :fixed='isTop'
                @click-left="onClickLeft" />
        </section>
        <!-- 商品信息 -->
        <section v-if="serviceType == 0" class="selectClass">
            <van-cell is-link title="货物状态" @click="selectReason(0)">{{ stateOfGoods || '请选择' }}</van-cell>
            <!-- 1688 -->
            <van-cell is-link title="退款原因" @click="tuikuan1688" v-if="tradeId">{{ refundReson1688Name || '请选择' }}</van-cell>
            <van-cell is-link title="退款原因" @click="selectReason(1)" v-else>{{ refundReson || '请选择' }}</van-cell>
            <van-cell title="退款金额">修改</van-cell>
            <van-cell-group>
                <van-field v-model="refundAmount" type="number" label="￥" label-width="5" placeholder="请输入退款金额" />
                <van-cell>
                    <span style="color: #999999;font-size: 14px;">可修改，最多¥{{ goodsInfo.paymentPrice + goodsInfo.freightPrice
                    }}，含邮￥{{
    goodsInfo.freightPrice ? goodsInfo.freightPrice.toFixed(2) : '0.00' }}</span>
                </van-cell>
                <van-field v-show="goodsInfo.needRice && this.shopType == '0'" v-model="needRice" type="number" label=""
                    label-width="5" placeholder="请输入退款驿马" />
                <van-cell v-show="goodsInfo.needRice && this.shopType == '0'">
                    <span style="color: #999999;font-size: 14px;">可修改，最多{{ goodsInfo.needRice }}驿马</span>
                </van-cell>
            </van-cell-group>

        </section>
        <section v-if="serviceType == 1" class="selectClass">
            <van-cell is-link title="退货退款原因" @click="selectReason(2)">{{ refundReson || '请选择' }}</van-cell>
            <van-cell title="退货数量">修改</van-cell>
            <van-cell-group>
                <van-field v-model="quantity" type="number" label=" " label-width="5" placeholder="请输入退款金额" />
                <van-cell>
                    <span style="color: #999999;font-size: 14px;">可修改，最多可输入{{ goodsInfo.quantity }}</span>
                </van-cell>
            </van-cell-group>
            <van-cell title="退款金额">修改</van-cell>
            <van-cell-group>
                <van-field v-model="refundAmount" type="number" label="￥" label-width="5" placeholder="请输入退款金额" />
                <van-cell>
                    <span style="color: #999999;font-size: 14px;">可修改，最多¥{{ goodsInfo.paymentPrice + goodsInfo.freightPrice
                    }}，含邮￥{{
    goodsInfo.freightPrice ? goodsInfo.freightPrice.toFixed(2) : '0.00' }}</span>
                </van-cell>
                <van-field v-show="goodsInfo.needRice && this.shopType == '0'" v-model="needRice" type="number" label=""
                    label-width="5" placeholder="请输入退款驿马" />
                <van-cell v-show="goodsInfo.needRice && this.shopType == '0'">
                    <span style="color: #999999;font-size: 14px;">可修改，最多{{ goodsInfo.needRice }}驿马</span>
                </van-cell>
            </van-cell-group>
        </section>
        <section class="uploadImg">
            <van-cell title="退款说明" />
            <div class="evaluateDetail">
                <van-field v-model="resonDescribe" type="textarea" placeholder="退款说明，有助于商家更好处理。" show-word-limit
                    maxlength="200" />
                <van-uploader v-model="fileLists" multiple preview-size="2.6rem" :max-count="4" :before-delete="deletePics"
                    :after-read="afterRead" accept="image/*" />
            </div>
        </section>
        <div style="height:1.3rem;width:100%;background:#ffffff;"></div>
        <div class="container-btn">
            <van-tabbar :fixed="isTop">
                <van-button type="primary" color="#E54320" block round @click="submitForm">提交</van-button>
            </van-tabbar>
        </div>
        <!-- 弹窗组件 -->
        <div class="vanCom">
            <van-action-sheet v-model="show" title="退款原因" safe-area-inset-bottom :closeable="isShowClose" cancel-text="提交"
                close-on-click-action @cancel="submitRefound">
                <!-- <p>取消后无法恢复优惠券可退回</p> -->
                <van-radio-group v-if="popList == 0" v-model="stateOfGoods">
                    <van-radio v-for="val in actions0" :key="val.name" :name="val.name" checked-color="#E54320"
                        icon-size="0.5rem">{{
                            val.name }}
                    </van-radio>
                </van-radio-group>
                <van-radio-group v-if="popList == 1" v-model="refundReson">
                    <van-radio v-for="val in actions1" :key="val.name" :name="val.name" checked-color="#E54320"
                        icon-size="0.5rem">{{
                            val.name }}
                    </van-radio>
                </van-radio-group>
                <van-radio-group v-if="popList == 2" v-model="refundReson">
                    <van-radio v-for="val in actions2" :key="val.name" :name="val.name" checked-color="#E54320"
                        icon-size="0.5rem">{{
                            val.name }}
                    </van-radio>
                </van-radio-group>
            </van-action-sheet>
        </div>
        
        <!-- 1688退款理由 -->
        <div class="vanCom">
            <van-action-sheet 
                v-model="show1688" 
                title="退款原因" 
                safe-area-inset-bottom 
                :closeable="isShowClose" cancel-text="提交"
                close-on-click-action>
                <van-radio-group v-model="refundReson1688" @change=change1688>
                    <van-radio 
                        v-for="val in actions3" 
                        :key="val.id" 
                        :name="val.id" 
                        checked-color="#E54320"
                        icon-size="0.5rem">{{ val.name }}</van-radio>
                </van-radio-group>
            </van-action-sheet>
        </div>
    </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { upload } from '@/api/tsMallorderDetail'
import { getServiceIdDetail, refundMoneyAgain, get1688reason } from '@/api/goodsService/index.js'
export default {
    data() {
        return {
            isTop: true,
            isShowClose: false,//不显示关闭按钮
            show: false,//退货退款原因弹窗
            refundAmount: 0,//退款金额
            needRice: 0,
            quantity: '',//退款退货数量
            serviceType: '',//退款类型 退款 0/退货退款1/换货2
            stateOfGoods: '',//货物状态
            refundReson: '',//退款/退货原因
            goodsInfo: {},//商品信息
            popList: 1,//区分弹窗内容货物状态0，退款1退货退款2
            fileLists: [],//图片列表
            resonDescribe: '',//评论文字
            resonUrlList: [],//图片列表
            timer: null,//定时器
            applyType: null,//判断是否为再次申请
            shopType: '',//区分订单是红尘坊的还是侠客集市的
            actions0: [{ name: '未收到货' }, { name: '已收到货' }],
            actions1: [{ name: '拍错/多拍/不喜欢' }, { name: '与商家协商一致退款' }, { name: '空包裹' }, { name: '快递/物流未送到' }, { name: '快递/物流无记录' }, { name: '货物破损已拒签' }],
            actions2: [{ name: '7天无理由' }, { name: '拍错/多拍/不喜欢' }, { name: '与商家协商一致退款' }, { name: '质量问题' }, { name: '少发/漏发' }, { name: '包装/商品破损/有污渍' }, { name: '假冒品牌' }, { name: '发错货' }],
            actions3: [],
            tradeId: '', // 有值1688退款 无值正常商品
            refundReson1688: '',
            show1688: false,
            refundReson1688Name: ''
        }
    },
    beforeMount() {
        this.serviceType = this.$route.query.code//区分退款类型
        this.shopType = this.$route.query.shopType//区分侠客集市/红尘坊
        console.log(this.$route.query, '携带数据');
        this.getPage()
        this.applyType = this.$route.query.applyType ? this.$route.query.applyType : null//是不是再次申请
        // this.refundAmount = this.$route.query.goodsInfo.paymentPrice + this.$route.query.goodsInfo.freightPrice
        // this.quantity = this.$route.query.goodsInfo.quantity
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    beforeDestroy() {
        clearTimeout(this.timer)
    },
    methods: {
        //获取订单信息
        getPage() {
            if (this.$route.query.itemId) {
                let itemId = this.$route.query.itemId
                // this.$store.commit('setSession', this.$route.query.session)
                getServiceIdDetail({ itemId, types: '1' }).then(res => {
                    if (res.data.code == 0) {
                        if (res.data.data.tradeId) {
                            this.get1688reason(res.data.data.tradeId)
                        }
                        this.goodsInfo = res.data.data
                        this.tradeId = res.data.data.tradeId
                        this.refundAmount = res.data.data.paymentPrice + res.data.data.freightPrice
                        this.needRice = res.data.data.needRice || 0
                        this.quantity = res.data.data.quantity
                    } else {
                        Toast(res.data.message);
                    }
                }).catch(() => {
                    Toast.fail('加载失败，请退出重试')
                })
            }
        },
        //选择退货退款弹出框
        selectReason(data) {
            this.popList = data
            this.show = true
            // console.log(data, '哪个？');
        },
        //点击返回
        onClickLeft() {
            this.$router.back()
        },
        //关闭页面
        closePage() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.close.postMessage("up");
            }
            if (isAndroid) {
                window.android.close();
            }
        },
        //上传照片
        /*  afterRead(file, detail) {
             // console.log(file, '上传')
             console.log(detail, 'detail')
             file.status = 'uploading'
             file.message = '上传中'
             let formData = new window.FormData()
             formData.append('file', file.file)
             formData.append('fileType', 'imgage/png'),
                 formData.append('dir', 'material')
             // console.log(formData, '参数哦')
             upload(formData).then(res => {
                 if (res.data.code === 0) {
                     file.status = ''
                     file.message = ''
                     this.resonUrlList.push(res.data.data)
                 }
             }).catch(() => {
                 file.status = 'failed',
                     file.message = '上传失败'
             })
         }, */
        afterRead(file, detail) {
            // 此时可以自行将文件上传至服务器
            console.log(file, '<===文件');
            console.log(detail, '<===detail文件');
            if (file.length > 1) {
                //使用for循环来适配多选情况
                for (let i in file) {
                    file[i].status = 'uploading'
                    file[i].message = '上传中'
                    this.upImg(file[i], detail)
                }
            } else {
                file.status = 'uploading'
                file.message = '上传中'
                this.upImg(file, detail)
            }
        },
        //上传图片封装
        upImg(file, detail) {
            console.log(detail, 'detail<==文件2');
            let formData = new window.FormData()
            formData.append('file', file.file)
            formData.append('fileType', 'imgage/png'),
                formData.append('dir', 'material')
            // console.log(formData, '参数哦')
            upload(formData).then(res => {
                if (res.data.code === 0) {
                    file.status = ''
                    file.message = ''
                    this.resonUrlList.push(res.data.data)
                }
            }).catch(() => {
                file.status = 'failed',
                    file.message = '上传失败'
            })
        },

        //删除照片
        deletePics(file, detail) {
            Dialog.confirm({
                title: '截图',
                message: '确认删除截图？'
            }).then(() => {
                this.fileLists.splice(detail.index, 1)
                this.resonUrlList.splice(detail.index, 1)
            }).catch(() => {
                Toast.fail('已取消')
            })
        },
        //提交
        submitForm() {
            if (this.serviceType == 0) {//退款的
                let data = {
                    itemId: this.$route.query.itemId,
                    types: '2',
                    status: '0',
                    // refundReson: this.refundReson,
                    refundReson: this.tradeId ? this.refundReson1688Name : this.refundReson,
                    refundReasonId: this.tradeId ? this.refundReson1688 : '',
                    stateOfGoods: this.stateOfGoods,
                    refundAmount: this.refundAmount,
                    needRice: this.shopType == '0' ? (this.goodsInfo.needRice ? this.needRice : 0) : 0,
                    resonDescribe: this.resonDescribe,
                    resonUrl: this.resonUrlList.join(',')
                }
                let reg = /^([0-9]{1}|^[1-9]{1}\d{1,15})(\.\d{1,2})?$/
                let reg1 = /^([0-9]{1}|^[1-9]{1}\d{1,15})(\.\d{1,4})?$/
                if (!data.stateOfGoods) {
                    Toast('请选择货物状态')
                    return
                }
                if (this.tradeId) {
                    if (!data.refundReasonId) {
                        Toast('请填写退款原因')
                        return
                    }
                } else {
                    if (!data.refundReson) {
                        Toast('请填写退款原因')
                        return
                    }
                }
                
                if (this.shopType == '0' && !reg1.test(data.needRice)) {
                    Toast('最多保留4位小数')
                    return
                }
                if (this.shopType == '0' && data.needRice > this.goodsInfo.needRice) {
                    Toast('退款驿马不得大于实际支付驿马')
                    return
                }
                if (!reg.test(data.refundAmount)) {
                    Toast('最多保留2位小数')
                    return
                }
                if (data.refundAmount > this.goodsInfo.paymentPrice + this.goodsInfo.freightPrice) {
                    Toast('退款金额不得大于实际支付金额')
                    return
                }
                if (!data.refundAmount) {
                    Toast('请填写退款金额')
                    return
                }
                if (!data.resonDescribe) {
                    Toast('请描述退款原因')
                    return
                }
                console.log(data, '<==退款原因');
                if (this.applyType == 2) {//重新申请退款的
                    data.saleId = this.$route.query.saleId
                    data.types = '0'
                    this.returnMoneyAgain(data)
                } else {//第一次申请退款的
                    this.applyReturnMoney(data)
                }

            } else if (this.serviceType == 1) {//退货退款的
                let data = {
                    itemId: this.$route.query.itemId,
                    types: '2',
                    status: '1',
                    refundReson: this.refundReson,
                    quantity: this.quantity,
                    refundAmount: this.refundAmount,
                    needRice: this.shopType == '0' ? (this.goodsInfo.needRice ? this.needRice : 0) : 0,
                    resonDescribe: this.resonDescribe,
                    resonUrl: this.resonUrlList.join(',')
                }
                let reg = /^([0-9]{1}|^[1-9]{1}\d{1,15})(\.\d{1,2})?$/
                let reg1 = /^[0-9]*[1-9][0-9]*$/
                let reg2 = /^([0-9]{1}|^[1-9]{1}\d{1,15})(\.\d{1,4})?$/
                if (!data.refundReson) {
                    Toast('请填写退款原因')
                    return
                }
                if (!reg1.test(data.quantity)) {
                    Toast('退货数量有误')
                    return
                }
                if (!data.quantity) {
                    Toast('请填写退货数量')
                    return
                }
                if (data.quantity > this.goodsInfo.quantity) {
                    Toast('退货数量不得大于实际数量')
                    return
                }
                if (this.shopType == '0' && !reg2.test(data.needRice)) {
                    Toast('最多保留4位小数')
                    return
                }
                if (this.shopType == '0' && data.needRice > this.goodsInfo.needRice) {
                    Toast('退款驿马不得大于实际支付驿马')
                    return
                }
                if (!reg.test(data.refundAmount)) {
                    Toast('最多保留2位小数')
                    return
                }
                if (data.refundAmount > this.goodsInfo.paymentPrice + this.goodsInfo.freightPrice) {
                    Toast('退款金额不得大于实际支付金额')
                    return
                }
                if (!data.refundAmount) {
                    Toast('请填写退款金额')
                    return
                }
                if (!data.resonDescribe) {
                    Toast('请描述退款原因')
                    return
                }
                console.log(data, '<==退款原因');
                if (this.applyType == 2) {//重新申请退货退款的
                    data.saleId = this.$route.query.saleId
                    data.types = '1'
                    // console.log(data, '再次申请');
                    this.returnMoneyAgain(data)

                } else {//第一次申请退货退款的
                    this.applyReturnMoney(data)
                }
            }
        },
        //关闭弹窗
        submitRefound() {
            console.log(this.refundReson, '原因');
        },
        //申请退款/退货退款
        applyReturnMoney(data) {
            getServiceIdDetail(data).then(res => {
                console.log(res, '<===提交申请');
                if (res.data.code == 0) {
                    Toast.success('申请成功');
                    this.timer = setTimeout(() => {
                        this.$router.go(-2);
                    }, 1500)
                } else {
                    Toast({
                        duration: 3000, // 持续展示 toast
                        message: res.data.message,
                    });
                    this.timer = setTimeout(() => {
                        this.$router.go(-2);
                    }, 1500)
                }
            }).catch((err) => {
                console.log(err, "err");
                Toast('退款申请失败');
                // this.backToList();
            });
        },
        //重新申请退款
        returnMoneyAgain(data) {
            refundMoneyAgain(data).then(res => {
                if (res.data.code == 0) {
                    Toast.success('申请成功');
                    this.timer = setTimeout(() => {
                        this.closePage()
                    }, 1500)
                } else {
                    Toast({
                        duration: 1500, // 持续展示 toast
                        message: res.data.message,
                    })
                    this.timer = setTimeout(() => {
                        this.closePage()
                    }, 1500)
                }
            }).catch((err) => {
                console.log(err, "err");
                Toast('退款申请失败');
                // this.backToList();
            });
        },

        get1688reason (orderId) {
            get1688reason({ orderId }).then(res => {
                if (res.data.code === 0) {
                    this.actions3 = res.data.data
                }
            })
        },

        change1688 (id) {
            const itme = this.actions3.find(x => x.id == id)
            this.refundReson1688Name = itme.name
        },

        tuikuan1688 () {
            this.show1688 = true
        }
    },
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    height: 100%;

    // background: #f7f7f7;
    .vanCom {
        .van-radio-group {
            padding: 0.4324rem 0.4324rem;

            .van-cell__title {
                margin: auto 0;
            }

            .van-radio {
                height: 1.5rem;
            }
        }
    }

    .fontColor {
        line-height: 0.7784rem;
        color: #666666;
        font-size: 12px;
    }

    .goodsStar {
        padding: 0.4324rem 0;

        p {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                margin-right: 0.3459rem;
            }
        }
    }

    section {
        background: #fff;
        margin-bottom: 18px;

    }

    .container-btn {
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;

        .van-tabbar {
            box-sizing: border-box;
            padding: 3px 10px;

            .van-button {
                width: 100%;
                height: 40px;
            }
        }
    }

    .uploadImg {
        .evaluateDetail {
            background: #F7F7F7;
            // padding: 0.2rem;

            .van-field {
                background: #F7F7F7;
            }

            .van-uploader {
                height: 6rem;
                margin-left: 0.4rem;
                margin-top: 0.4rem;

                /deep/.van-uploader__upload {
                    border: 1px dashed #999999;
                    margin: 1px;
                }

            }

        }
    }

    .top {
        height: 6%;
        border-bottom: 1px solid #E5E5E5;
        font-size: 1.2973rem;
    }

    .shopInfo {
        display: flex;
        flex-flow: column nowrap;
        padding: 0.4324rem 0.5189rem;

        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding-top: 0.4324rem;
            margin-bottom: 0.4324rem;

            .goodsList {
                display: flex;
                flex-flow: row nowrap;
                margin-bottom: 0.4324rem;

                .goodsText {
                    margin-left: 0.5189rem;
                    display: flex;
                    flex: 1;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        width: 6.4865rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .evaluateDetail {
                background: #F7F7F7;
                // padding: 0.2rem;

                .van-field {
                    background: #F7F7F7;
                }

                .van-uploader {
                    height: 6rem;
                    margin-left: 0.4rem;
                    margin-top: 0.4rem;

                    /deep/.van-uploader__upload {
                        border: 1px dashed #999999;
                        margin: 1px;
                    }

                }

            }
        }

    }

}
</style>